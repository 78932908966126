<template>
  <div class="center1">
    <div class="center">
      <!-- 侧导航 -->
      <div class="aside_left">
        <router-link to="/auth"><el-menu class="home">个人中心</el-menu></router-link>
        <el-menu :default-active="activePath"
                 class="el-menu-vertical-demo"
                 :default-openeds='openeds'
                 router
                 @open="handleOpen"
                 @close="handleClose">

          <el-submenu :index="idx+''"
                      v-for="item,idx in routerData"
                      :key="idx">
            <template slot="title">{{item.name}}</template>
            <el-menu-item :index="ite.path"
                          v-for="(ite,i) in item.children"
                          :key="i">{{ite.name}}
            </el-menu-item>
          </el-submenu>
        </el-menu>

      </div>
      <div class="aside_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activePath: this.$route.path,
        openeds: ["1"],
        routerData: [
          // {
          //   path: "",
          //   name: "个人中心",
          // },
          {
            path: "",
            name: "产品管理",
            children: [
              {
                path: "/workbench/product",
                name: "产品列表",
              },
            ],
          },
        ],
      };
    },
    created() {
      this.activePath = this.$route.path;
      if (this.$route.path.indexOf("/bankCardBind") != -1) {
        this.activePath = "/bankCardBind";
      } else if (this.$route.path.indexOf("/virtualBnakList") != -1) {
        console.log(this.$route.path);
        this.activePath = "/virtualBnakList";
      }
    },
    watch: {
      $route: {
        handler(to, from) {
          if (this.$route.path.indexOf("/bankCardBind") != -1) {
            this.activePath = "/bankCardBind";
          } else if (this.$route.path.indexOf("/virtualBnakList") != -1) {
            console.log(this.$route.path);

            this.activePath = "/virtualBnakList";
          }
        },
      },
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
    },
  };
</script>

<style lang="less" scoped>
  .center1 {
    background-color: #f6f7fb !important;

    .center {
      padding: 20px 0 30px 0;
      box-sizing: border-box;
      display: flex;
    }
  }

  .aside_left {
    width: 180px;
    background-color: #fff;
    margin-right: 20px;
    height: calc(100vh - 446px);
  }

  .aside_right {
    flex: 1;
  }

  /deep/ .el-icon-arrow-down:before {
    content: none;
  }

  /deep/ .el-submenu {
    border-bottom: 1px solid #f0f3ef;
    min-width: 180px;
  }

  /deep/ .el-menu-item {
    height: 50px;
    min-width: 180px !important;
    padding: 0 0 0 20px !important;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 50px;
    letter-spacing: 1px;
  }

  /deep/ .el-menu-item.is-active {
    color: #c70009 !important;
  }

  /deep/ .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #fff;
  }

  /deep/ .el-submenu__title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
    padding: 20px;
    box-sizing: border-box;
    height: 65px;
  }

  /deep/ .el-menu {
    border-right: none;
  }

  .home{
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
    padding: 20px;
    box-sizing: border-box;
    height: 65px;
  }

  .home:hover{
    background-color: #ecf5ff;
  }
</style>
<style lang='less'>
  /deep/ .el-submenu .el-menu-item {
    min-width: none !important;
  }
</style>